<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      placeholder="Hệ đào tạo"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      placeholder="Khóa học"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label-for="departmentId"
                  >
                    <v-select
                      v-model="filter.departmentId"
                      :options="departments"
                      :reduce="option => option.value"
                      placeholder="Khoa/Bộ môn"
                      @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="majorId"
                  >
                    <v-select
                      v-model="filter.majorId"
                      :options="majors"
                      :reduce="option => option.value"
                      placeholder="Ngành học"
                      @input="onMajorsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="programmeId"
                  >
                    <v-select
                      id="programmeId"
                      v-model="filter.programmeId"
                      :options="programmes"
                      :reduce="option => option.value"
                      placeholder="Chương trình ĐT"
                      @input="onProgrammesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="classId"
                    title="Lớp độc lập"
                  >
                    <v-select
                      id="classId"
                      v-model="filter.classId"
                      :options="classes"
                      :reduce="option => option.value"
                      placeholder="Lớp độc lập"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-if="creatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mt-0"
                      @click="onBulkInsertMainData"
                    >
                      <feather-icon icon="PlusSquareIcon" /> Lập danh sách
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-0 ml-1"
                      @click="onSearchMainData"
                    >
                      <feather-icon icon="ListIcon" /> Xem danh sách
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  max-height="500px"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'currentQty'">
                      {{ props.row.approvedQty + '/' + props.row.currentQty }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          variant="info"
                          class="btn-icon"
                          size="sm"
                          @click="onShowDiplomaStudents(props.row)"
                        >
                          <feather-icon icon="ListIcon" />
                        </b-button>
                        <b-button
                          v-if="deletable"
                          :disabled="(props.row.status >= 1)"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

    <b-modal
      id="modal-view-subject"
      ref="modal-view-subject"
      ok-title="Lưu và Duyệt"
      cancel-title="Đóng"
      centered
      size="xl"
      title="Chi tiết môn học sinh viên được xét duyệt liên thông"
      @ok="onApproveTransfer"
    >
      <StudentTransferSave :data-send="currentMainData" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BButtonGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import StudentTransferSave from '@/views/student-transfer/StudentTransferSave.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const Attr = Object.freeze({
  store: 'studentTransfer',
})
export default {
  name: 'StudentTransfer',
  directives: {
    Ripple,
  },
  components: {
    StudentTransferSave,
    BButton,
    BButtonGroup,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: null,
        trainingSystemId: null,
        courseId: null,
        departmentId: null,
        majorId: null,
        programmeId: null,
        classId: null,
        code: null,
        name: null,
        sort: null,
        status: null,
      },
      currentMainData: undefined,
      extMainData: {
        planClassNum: 1,
        planClassData: [],
        planStudentClassData: [],
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      currentName: '',
      currentCode: '',
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
      classes: 'dropdown/classes',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Họ tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Lớp cố định',
          field: 'className',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Chương trình ĐT',
          field: 'programmeName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khóa',
          field: 'courseName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày tạo',
          field: 'createdAt',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày duyệt',
          field: 'approvedAt',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.STUDENT_TRANSFER)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.STUDENT_TRANSFER)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.STUDENT_TRANSFER)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await Promise.all([
        this.getTrainingSystems(this.filter),
        this.getDepartments(this.filter),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getClasses: 'dropdown/getClasses',
      createPlanData: `${Attr.store}/createPlanData`,
      updateData: `${Attr.store}/updateData`,
      deleteMainData: `${Attr.store}/deleteData`,
    }),
    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses({ trainingSystemId: event })
    },
    async onCoursesChange() {
      // Loc lai CTDT, Lop doc lap
      const request = { ...this.filter }
      request.status = 1
      await this.getProgrammes(request)
      await this.getClasses(request)
    },
    async onDepartmentChange(event) {
      this.filter.majorId = null
      await this.getMajors({ departmentId: event })
    },
    async onMajorsChange() {
      this.filter.programmeId = null
      const request = { ...this.filter }
      request.status = 1
      await this.getProgrammes(request)
    },
    async onProgrammesChange() {
      this.filter.classId = null
      const request = { ...this.filter }
      request.status = 1
      await this.getClasses(request)
    },
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {}
    },
    onEditMainData(mainData) {
      this.isCopy = false
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName}? <span class="text-danger">${mainData.name}</span>`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainData(this.filter)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
    onShowDiplomaStudents(data) {
      this.currentMainData = data
      this.$bvModal.show('modal-view-subject')
    },
    async onBulkInsertMainData() {
      try {
        const response = await this.createPlanData(this.filter)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getMainData(this.filter)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async onApproveTransfer() {
      this.isLoading = true
      const oldData = { ...this.currentMainData }
      try {
        const request = {
          id: this.currentMainData.id,
          status: 1,
          studentId: this.currentMainData.studentId,
          programmeId: this.currentMainData.programmeId,
          approvedDate: '',
        }
        const response = await this.updateData(request)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            await this.getMainDataFromStore()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            // eslint-disable-next-line no-undef
            this.currentMainData.status = oldData.status
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
